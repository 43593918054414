declare global {
  interface Window {
    pipedriveLeadboosterConfig: any;
    LeadBooster: any;
  }
}
const usePipedriveChat = () => {
  window.pipedriveLeadboosterConfig = {
    base: process.env.GATSBY_PIPEDRIVE_BASE_URL,
    companyId: parseInt(process.env.GATSBY_PIPEDRIVE_COMPANY_ID || '', 10),
    playbookUuid: process.env.GATSBY_PIPEDRIVE_PLAYBOOK_UUID,
    version: parseInt(process.env.GATSBY_PIPEDRIVE_VERSION || '', 10),
  };
  const w = window;
  if (w.LeadBooster) {
    // eslint-disable-next-line no-console
    console.warn('LeadBooster already exists');
  } else {
    w.LeadBooster = {
      q: [],
      on: (n: any, h: any) => {
        w.LeadBooster.q.push({ t: 'o', n, h });
      },
      trigger: (n: any) => {
        w.LeadBooster.q.push({ t: 't', n });
      },
    };
  }
};

export default usePipedriveChat;